import { extendTheme } from '@mui/joy/styles';
import { Colors } from './Resources';

const palette = {
    primary: {
     	solidBg: Colors.blue,
      	// solidBorder: '#0d6efd',
      	// solidHoverBg: '#0b5ed7',
      	// solidHoverBorder: '#0a58ca',
      	// solidActiveBg: '#0a58ca',
      	// solidActiveBorder: '#0a53be',
      	// solidDisabledBg: '#0d6efd',
      	// solidDisabledBorder: '#0d6efd',
    },
    // neutral: {
    // },
    // success: {
    // },
    // danger: {
    // },
    // warning: {
    // },
    // info: {
    // },
};

const muiTheme = extendTheme({
    colorSchemes: {
     	light: { palette },
     	dark: { palette },
    }
});

export default muiTheme;