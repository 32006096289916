import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useLangNavigate as useNavigate } from '../modules/i18n';

import { Colors, Dimens, MoguaSVG } from '../Resources';
import { AndroidSVG, AppleSVG, FlutterSVG, UnitySVG, CocosSVG } from '../Resources';
import { moguaDemoAppKey } from '../GlobalConfig';

import SEO from '../modules/SEO';
import NavBar from './NavBar';
import Footer from './Footer';
import Markdown from './MoguaMarkdown';
import LiveDemoForm from './LiveDemoForm';
import { Box, Typography, Button, Stack, Divider, AspectRatio, Link, Chip, Card, Grid } from '@mui/joy';
import { List, ListItem } from '@mui/joy';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faCircleRight, faCircleCheck, faBook, faThumbsUp, faHeartCircleCheck, faShieldHalved, faRocket } from '@fortawesome/free-solid-svg-icons';
import { LineChart, markElementClasses } from '@mui/x-charts/LineChart';

import {
	Experimental_CssVarsProvider as CssVarsProvider,
	experimental_extendTheme as extendTheme,
} from '@mui/material/styles';
const theme = extendTheme();

const themeGreen = '#2BD965';
const checkIcon = <FA icon={faCircleCheck} color={themeGreen} />;


const HomeZh: React.FC = () => {
	const nav = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const s = searchParams.get('s');
	const s1ref = React.useRef(null);
	const s2ref = React.useRef(null);

	React.useEffect(()=>{
		if (!s) return;
		if (s === '1' && s1ref && s1ref.current) {
			(s1ref as any).current.scrollIntoView({ behavior: 'smooth', block: 'start' });	
		} else if (s === '2' && s2ref && s2ref.current) {
			(s2ref as any).current.scrollIntoView({ behavior: 'smooth', block: 'start' });	
		}
		nav('/', { replace: true });
	}, [s, s1ref, s2ref, nav]);

	return <>
		<SEO
			title='Mogua: 免填邀请码'
			description="Web to app parameter passing solution. Track Android and iOS apps' installation from web referrals with our attribution SDK."
			url='https://www.mogua.io/zh' />
		<NavBar bgcolor='#071C46' fgcolor='white' />
		<Banner />
		<Section1 refProp={s1ref} />
		<Section2 />
		<DemoSection />
		<Section4 refProp={s2ref} />
		<Box mx='auto' { ...Dimens.autoContentWidth }><Divider /></Box>
		<Section3 />
		<Footer />
    </>;
}

export default HomeZh;


const Banner: React.FC = () => {
	const nav = useNavigate();
	// let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
	return <Box sx={{ background: `linear-gradient(#071C46, #2F6EED 50%, #EFEEFE 90%, white 100%)` }}>
		<Box mt={8} mb={16} mx='auto' { ...Dimens.autoContentWidth }>
			<Box display='flex' flexDirection={{ xs: 'column', sm: 'column', md: 'row' }} alignItems='start' gap={3}>
				<Typography fontSize={72} fontWeight='bold' textColor='white' sx={{ lineHeight: 1.1, flex: 1 }}>APP渠道统计<br />免填邀请码安装</Typography>
				{/*<Box display='flex' flexDirection='column' alignItems='center' mr={9} p={2} sx={{ borderRadius: 12, bgcolor: '#00000044' }}>
					<Typography fontSize={16} textColor='white' sx={{ opacity: 0.75 }}>MoguaSDK 支持平台及框架</Typography>
					<Stack direction='row' spacing={2} mt={1.5}>
						<AndroidSVG size='30' />
						<AppleSVG size='28' color='#dad7d0BB' />
						<FlutterSVG size='28' />
						<UnitySVG size='28' />
						<CocosSVG size='28' />
					</Stack>
				</Box>*/}
			</Box>
			<Divider sx={{ mt: 4, background: 'linear-gradient(0.25turn, transparent, white 5%, transparent)' }} />
			<Typography fontSize={36} fontWeight='bold' textColor='white' mt={3}>网页到 APP 安装自动传参方案</Typography>
			<Typography fontSize={20} fontWeight='bold' textColor='white'>支持 iOS · Android · 应用商店安装 · 下载安装</Typography>
			<Button size='lg' endDecorator={<FA icon={faCircleRight} />} sx={{ mt: 5, bgcolor: Colors.blue }} onClick={()=>nav('/sign-up')}>
				免费体验
			</Button>
		</Box>
	</Box>
}


const Section1: React.FC<{ refProp?: any }> = ({ refProp }) => {
	return <Box ref={refProp} sx={{ background: 'linear-gradient(white, #FEFEFE)' }}>
		<Box mt={4} mx='auto' { ...Dimens.autoContentWidth }>
			<Typography fontSize={20} fontWeight='bold' textColor='#323B4D' ml={3}>经典场景</Typography>
			<Box display='flex' flexDirection={{ xs: 'column', sm: 'column', md: 'row' }} mt={1} borderRadius={12} overflow='hidden'
				sx={{ background: 'linear-gradient(#323B4D, #3F5173)' }}>
				<Box p={3} flex={0.8}>
					<Typography fontSize={36} fontWeight='bold' textColor='white'>APP 安装来源区分 · 渠道统计</Typography>
					<Typography fontSize={16} textColor={'#FFFFFF60'} mt={2}>
						常见方案：每个渠道提供独立下载页和安装包，发版维护成本极高。如通过应用商店安装，则难以追踪推广来源。
					</Typography>
					<Divider sx={{ color: '#FFFFFF60', my: 2, '--Divider-lineColor': '#FFFFFF22' }}>vs</Divider>
					<Typography fontSize={16} textColor='white'>
						Mogua：下载页URL上携带渠道参数，APP下载安装后，即可获取参数，区分推广渠道。
					</Typography>
					<List sx={{ fontSize: 16, mt: 2 }}>
						<ListItem sx={{ color: themeGreen }}>{checkIcon}仅需一个下载页，一个安装包</ListItem>
						<ListItem sx={{ color: themeGreen }}>{checkIcon}支持 AppStore，GooglePlay</ListItem>
						<ListItem sx={{ color: themeGreen }}>{checkIcon}适用于任意推广方式</ListItem>
					</List>
				</Box>
				<Box display='flex' flexDirection='column' mt={6} ml={8} flex={1} bgcolor={Colors.grayBG} sx={{ borderRadius: '12px 0 0 0', boxShadow: '8px 8px 8px #00000066' }}>
					<Box display='flex' flexDirection='row' alignItems='center' gap={3} pl={2} py={1}>
						<MoguaSVG size='sm' />
						<Typography fontSize={12}>控制台</Typography>
					</Box>
					<Divider />
					<Box display='flex' flexDirection='row' flex={1}>
						<Box display='flex' flexDirection='column' p={2} gap={2}>
							<Box display='flex' flexDirection='row' gap={1}>
								<Box width={40} height={40} bgcolor={Colors.blue} borderRadius={8} />
								<Box>
									<Box width={60} height={16} mt={0.5} bgcolor={Colors.gray1} borderRadius={2} />
									<Box width={48} height={12} mt={0.5} bgcolor={Colors.gray1} borderRadius={2} />
								</Box>
							</Box>
							<Divider />
							<Typography fontSize={12} startDecorator={<Box component='span' width={10} height={10} bgcolor={Colors.gray1} />}>App 信息</Typography>
							<Divider />
							<Typography fontSize={12} startDecorator={<Box component='span' width={10} height={10} bgcolor={Colors.gray1} />}>统计分析</Typography>
							<Divider />
							<Typography fontSize={12} startDecorator={<Box component='span' width={10} height={10} bgcolor={Colors.gray1} />}>SDK 集成</Typography>
						</Box>
						<Divider orientation='vertical' />
						<Box sx={{ flex: 1 }}>
							<CssVarsProvider theme={theme}><LineChart
								tooltip={{ trigger: 'none' }}
								slotProps={{ legend: {
									labelStyle: { fontSize: 12 },
									itemMarkWidth: 8,
									itemMarkHeight: 8,
									itemGap: 20,
								} }}
								grid={{ horizontal: true }}
								sx={{
									[`& .${markElementClasses.root}`]: { fill: '#fff' },
									'& .MuiChartsAxis-line': {
										stroke: Colors.gray2,
									},
									'& .MuiChartsAxis-tick': {
										stroke: Colors.gray2,
									},
									'& .MuiChartsAxis-tickLabel': {
										fill: Colors.gray2,
									},
								}}
								xAxis={[{ dataKey: 'date', scaleType: 'band' }]}
								yAxis={[{ id: 'aid' }]}
								colors={[ Colors.blue, Colors.orangeFG, Colors.greenFG ]}
								series={[
									{ label: '渠道 A', dataKey: 'a' },
									{ label: '渠道 B', dataKey: 'b' },
									{ label: '渠道 C', dataKey: 'c' },
								]}
								dataset={[
									{ 'id': 0, 'date': 'Day 0', 'a': 2, 'b': 1, 'c': 6 },
									{ 'id': 1, 'date': 'Day 1', 'a': 4, 'b': 5, 'c': 5 },
									{ 'id': 2, 'date': 'Day 2', 'a': 6, 'b': 7, 'c': 3 },
									{ 'id': 3, 'date': 'Day 3', 'a': 7, 'b': 8, 'c': 2 },
								]}
							/></CssVarsProvider>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	</Box>;
}


const Section2: React.FC = () => {
	return <Box sx={{ background: `linear-gradient(#FEFEFE, ${Colors.grayBG})` }}>
		<Box mt={8} mx='auto' { ...Dimens.autoContentWidth }>
			<Typography fontSize={20} fontWeight='bold' textAlign='end' textColor='#323B4D' mr={3}>经典场景</Typography>
			<Box display='flex' flexDirection={{ xs: 'column-reverse', sm: 'column-reverse', md: 'row' }} mt={1} gap={4} borderRadius={12} overflow='hidden'
				sx={{ background: 'linear-gradient(#323B4D, #3F5173)' }}>
				<Box display='flex' flexDirection='row' alignItems='center' mt='auto' mb={0} mx={4} pt={6} flex={1}>
					<AspectRatio ratio={3/4} variant='plain' sx={{ flex: 1, borderRadius: '32px 32px 0 0', boxShadow: '0px 8px 8px #00000066' }}>
						<Box display='flex' flexDirection='column' bgcolor='#EEEEEE'>
							<AspectRatio ratio={4} variant='plain' sx={{ width: '25%', mx: 'auto', mt: 1.5, backgroundColor: Colors.gray2, borderRadius: 16 }} />
							<Box maxWidth='60%' ml={1.5} mr='auto' mt='auto' mb={1} p={1} bgcolor='white' borderRadius={8}>
								<Typography fontSize={12} textColor='black'>
									给你看个 App，这里下载 <Link>https://download-app?inviter=momo</Link>
								</Typography>
							</Box>
							<Box minWidth='10%' ml='auto' mr={1.5} mt={1} mb='auto' p={1} bgcolor='#A9E87B' borderRadius={8}>
								<Typography fontSize={12} textColor='black'>
									OK 🤟🏼
								</Typography>
							</Box>
						</Box>
					</AspectRatio>
					<Box height={2} mt={2} mx={1} bgcolor='#FFFFFF33' flex={0.2} />
					<AspectRatio ratio={3/4} variant='plain' sx={{ flex: 1, borderRadius: '32px 32px 0 0', boxShadow: '0px 8px 8px #00000066' }}>
						<Box display='flex' flexDirection='column' bgcolor='#EEEEEE'>
							<AspectRatio ratio={4} variant='plain' sx={{ width: '25%', mx: 'auto', mt: 1.5, backgroundColor: Colors.gray2, borderRadius: 16 }} />
							<Box width='75%' my='auto' py={1} textAlign='center' bgcolor='white' borderRadius={8}>
								<Typography fontSize={24}>😎</Typography>
								<Typography fontSize={12} mb={2}>momo 邀请了你！</Typography>
								<Divider sx={{ my: 1 }}/>
								<Typography fontSize={12} textColor={Colors.blue}>OK</Typography>
							</Box>
						</Box>
					</AspectRatio>
				</Box>
				<Box p={3} flex={0.8}>
					<Typography fontSize={36} fontWeight='bold' textColor='white'>免填邀请码 · 用户裂变</Typography>
					<Typography fontSize={16} textColor={'#FFFFFF60'} mt={2}>
						常见方案：邀请拉新，APP启动后，要求用户填写邀请码。用户体验差，填写意愿低，转化效果差。
					</Typography>
					<Divider sx={{ color: '#FFFFFF60', my: 2, '--Divider-lineColor': '#FFFFFF22' }}>vs</Divider>
					<Typography fontSize={16} textColor='white'>
						Mogua：下载页URL上携带邀请码，APP下载安装后，即可获取邀请码，无需填写。
					</Typography>
					<List sx={{ fontSize: 16, mt: 2 }}>
						<ListItem sx={{ color: themeGreen }}>{checkIcon}邀请过程用户无感知</ListItem>
						<ListItem sx={{ color: themeGreen }}>{checkIcon}邀请方、受邀方均无额外操作</ListItem>
					</List>
				</Box>
			</Box>
		</Box>
	</Box>
}

const DemoSection: React.FC = () => {
	return <Box bgcolor={Colors.grayBG}>
		<Stack
			direction={{ xs: 'column-reverse', sm: 'column-reverse', md: 'row'}}
			alignItems={{ xs: 'center', sm: 'center', md: 'flex-start' }}
			justifyContent="center"
			spacing={10} mx='auto' my={12} sx={{ ...Dimens.autoContentWidth }}>
			<Box flex={1}>
				<Typography fontSize={36} fontWeight='bold'>广泛的应用场景</Typography>
				<Typography fontSize={16} textColor={Colors.gray3} mt={2}>落地页下载，聊天分享连接，地推扫码下载。通过在 URL 中携带参数，使用 MoguaSDK 实现从 Web 到 App 安装的参数传递。从而实现归因、用户裂变、应用矩阵，等系列常见推广方案。</Typography>
			</Box>
			<Card size='lg' sx={{ flex: 1 }}>
				<Typography fontSize={20} fontWeight='bold' textColor={Colors.black}>在线体验，仅需10秒！</Typography>
				<LiveDemoForm appKey={moguaDemoAppKey} appName='Mogua Demo' appIcon='https://mogua-us.oss-us-west-1.aliyuncs.com/public/110cbcffd017.1713857260.png' />			
			</Card>
		</Stack>
	</Box>;
}

const Section3: React.FC = () => {
	const nav = useNavigate();
	return <Box display='flex' flexDirection={{ xs: 'column', sm: 'column', md: 'row' }} my={12} mx='auto' gap={8}
		{ ...Dimens.autoContentWidth }>
		<Box display='flex' flexDirection='column'>
			<Typography fontSize={36} fontWeight='bold'>为开发者设计</Typography>
			<Typography fontSize={16} textColor={Colors.gray3} mt={2}>精简的核心功能，完善的文档指南。配置、集成简单，支持主流平台及框架。</Typography>
			<Button size='lg' startDecorator={<FA icon={faBook} />} sx={{ my: 6, width: 'max-content' }} onClick={()=>nav('/docs')}>开发文档</Button>
			<Stack direction='row' spacing={2}>
				<Chip variant='plain' startDecorator={<AndroidSVG />}>Android</Chip>
				<Chip variant='plain' startDecorator={<AppleSVG />}>iOS</Chip>
				<Chip variant='plain' startDecorator={<FlutterSVG />}>Flutter</Chip>
				<Chip variant='plain' startDecorator={<UnitySVG />}>Unity</Chip>
				<Chip variant='plain' startDecorator={<CocosSVG />}>Cocos2d</Chip>
			</Stack>
		</Box>
		<Markdown src='/code.md' sx={{ maxWidth: { md: '55%' } }} />
	</Box>;
}


const Section4: React.FC<{ refProp?: any }> = ({ refProp }) => {
	const nav = useNavigate();
	return <Box ref={refProp} mx='auto' my={12} { ...Dimens.autoContentWidth }>
		<Typography fontSize={36} fontWeight='bold'>产品优势</Typography>
		<Grid container rowSpacing={6} columnSpacing={6} textAlign='left' mt={2}>
			<Grid xs={12} sm={6}>
				<Typography fontSize={20} fontWeight='bold' startDecorator={<FA icon={faThumbsUp} />}>极致的性价比</Typography>
				<Typography fontSize={16} textColor={Colors.gray3} mt={2}>Mogua 的核心功能是⎡网页到 App 自动传参⎦，以及数据统计。我们不堆砌功能，因此能以最低的价格，提供极致的性价比服务。</Typography>
			</Grid>
			<Grid xs={12} sm={6}>
				<Typography fontSize={20} fontWeight='bold' startDecorator={<FA icon={faHeartCircleCheck} />}>充分的免费试用</Typography>
				<Typography fontSize={16} textColor={Colors.gray3} mt={2}>Mogua 为每个应用提供长达30天的免费试用，您可以充分测试推广效果。</Typography>
			</Grid>
			<Grid xs={12} sm={6}>
				<Typography fontSize={20} fontWeight='bold' startDecorator={<FA icon={faShieldHalved} />}>安全</Typography>
				<Typography fontSize={16} textColor={Colors.gray3} mt={2}>MoguaSDK 不收集 App 业务数据，不请求 App 隐私权限。</Typography>
			</Grid>
			<Grid xs={12} sm={6}>
				<Typography fontSize={20} fontWeight='bold' startDecorator={<FA icon={faRocket} />}>快速接入</Typography>
				<Typography fontSize={16} textColor={Colors.gray3} mt={2}>MoguaSDK 使用简单，10分钟即可完成集成。</Typography>
			</Grid>
		</Grid>
		<Button size='lg' startDecorator={<FA icon={faRocket} />} sx={{ mt: 6 }} onClick={()=>nav('/sign-up')}>立即试用</Button>
	</Box>;
}


