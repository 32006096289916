import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useLangNavigate as useNavigate } from '../modules/i18n';

import { Mogua } from 'moguasdk';
import { Colors, Dimens, AndroidSVG, AppleSVG, FlutterSVG, UnitySVG, CocosSVG } from '../Resources';
import { Utils, moguaDemoAppKey } from '../GlobalConfig';

import SEO from '../modules/SEO';
import NavBar from './NavBar';
import Footer from './Footer';
import { SectionIntro } from './SectionIntro';
import { QRCodeSVG } from 'qrcode.react';

import { Box, Typography, Button, Divider, Chip } from '@mui/joy';
import { Card, CardOverflow, CardContent } from '@mui/joy';
import { Grid } from '@mui/joy';

import { KeyboardArrowRight } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp, faIcons, faRectangleAd, faEnvelope, faBook } from '@fortawesome/free-solid-svg-icons'


const Products: React.FC = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const openToDownload = searchParams.get('otd');

	const moguaCall = React.useCallback(() => {
		if (Mogua.isReady()) return;
		Mogua.init({
			appKey: moguaDemoAppKey,
			onSuccess: () => Utils.isAndroid() ? Mogua.openOrDownload() : alert('Mogua Demo iOS is not ready yet.'),
			// onError: (error) => alert(error),
		});
	}, []);

	React.useEffect(() => {
		if (!openToDownload) return;
		moguaCall();
	}, [openToDownload, moguaCall]);

	return (<>
		<SEO
			title='Mogua: Deep Linking Solution'
			description='Deliver seamless mobile Journeys. Take customers straight to relevant content with engaging, personalized deep linking.'
			url='https://www.mogua.io/deep-linking' />
		<NavBar />
		<Divider />
		<Banner />
		<Section1 />
		<Section2 moguaCall={moguaCall} />
		<Section3 />
		<Footer />
	</>);
}

export default Products;

const Banner: React.FC = () => {
	const nav = useNavigate();
	return (<>
		<Box textAlign="center" mt={9} mx='auto' sx={{ ...Dimens.autoContentWidth }}>
			<Typography sx={{ color: Colors.black, fontSize: '72px', fontWeight: 'bold', lineHeight: 1.1, letterSpacing: '-0.15rem', textAlign: 'center' }}>
				Deep Linking - Track And Engage With Your App’s Users
			</Typography>
			<Box display='flex' mt={8} gap={4} alignItems='center'>
				<Box textAlign='left'>
					<Typography fontSize={32} fontWeight='bold' mb={2}>What is deep linking?</Typography>
					<Typography fontSize={16} textColor={Colors.gray3}>
						Mobile app deep linking is a technology that launches an app and opens a specific page when a user clicks a URL on a web page.<br />
						<br />
						<Typography fontWeight='bold' textColor='black'>Deferred – App not installed</Typography><br />
						Deferred deep links can route users to specific content through an install. The link will first redirect to the App Store or Play Store, and once the app is downloaded and opened, the user will be taken to the content they expect.<br />
						<br />
						<Typography fontWeight='bold' textColor='black'>Direct – App already installed</Typography><br />
						Typically deep links route users to app content as long as the app is already installed when the link is opened.
					</Typography>
				</Box>
				<img alt='' src='/images/flow.png' height={260} />
			</Box>
			<Button size="lg" endDecorator={<KeyboardArrowRight />} sx={{ mt: 6, pr: 2, "--Button-gap": "4px" }} onClick={()=>nav('/sign-up')}>Get Started</Button>
			<Divider sx={{ mt: 9 }} />
		</Box>
	</>);
}


const Section1: React.FC = () => {
	const nav = useNavigate();
	return (<>
		<SectionIntro textAlign='center' mt={9} mx='auto' sx={{ ...Dimens.autoContentWidth }}
			title='Reliable measurement of every marketing channel'
			desc='By tracking the sessions of the web and app pages, the app can track and measure the user’s source and engagements.'
			/>
		<Box textAlign='center' mt={6} mx='auto' sx={{ ...Dimens.autoContentWidth }}>
			<Grid container rowSpacing={6} columnSpacing={6} textAlign='left'>
				<Grid xs={12} sm={6}>
					<SectionIntro
						icon={<FontAwesomeIcon icon={faThumbsUp} />}
						title='Referrals'
						desc='Make your app shareable by letting users invite their friends and family. Serve personalized experiences, even rewarded referrals, while ensuring you have full visibility into clicks, installs, and opens.'
					/>
				</Grid>
				<Grid xs={12} sm={6}>
					<SectionIntro
						icon={<FontAwesomeIcon icon={faIcons} />}
						title='Social Media'
						desc='Engage your social media following and drive organic growth with contextual experiences provided by powerful deep links. Measure the impact of every social link with comprehensive reporting.'
					/>
				</Grid>
				<Grid xs={12} sm={6}>
					<SectionIntro
						icon={<FontAwesomeIcon icon={faRectangleAd} />}
						title='Paid Campaigns'
						desc='Report on the impact of every link while giving your users the ideal ad experience, with powerful links that send users to the right place every time, no matter the device or platform.'
					/>
				</Grid>
				<Grid xs={12} sm={6}>
					<SectionIntro
						icon={<FontAwesomeIcon icon={faEnvelope} />}
						title='EMails'
						desc='Increase email-to-app conversion with powerful links that provide users contextual experiences and while ensuring you have deep insights into email performance.'
					/>
				</Grid>
			</Grid>
			<Button size='lg' variant='outlined' color='neutral' endDecorator="😎" sx={{ mt: 6 }} onClick={()=>nav('/sign-up')}>Try It Now!</Button>
			<Divider sx={{ mt: 9 }} />
		</Box>
	</>);
}

const Section2: React.FC<{ moguaCall: ()=>void }> = ({ moguaCall }) => {
	const nav = useNavigate();
	const isMobile = Utils.isMobile();
	const gridSx = { textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' };
	const channel = 'Mogua%20Deep%20Link';
	const puppy = '%F0%9F%90%B6';
	const fox = '%F0%9F%A6%8A';
	return (<>
		<SectionIntro textAlign='center' mt={9} mx='auto' sx={{ ...Dimens.autoContentWidth }}
			title='Try instantly on our live demo'
			desc='Learn how to navigate a user to a specific page of the app and track the sources with MoguaSDK'
			/>
		<Box display='flex' flexDirection='column' alignItems='center' mt={6} mx='auto' gap={4} sx={{ ...Dimens.autoContentWidth }}>
			<Typography>1. {isMobile ? 'Choose' : 'Scan the code from'} the 🐶 or 🦊 to download our demo app on your phone.</Typography>
			<Box display='flex' gap={4}>
				<Card size='lg' sx={{ alignItems: 'center' }}>
					{!isMobile && <Box width={96} display='flex' justifyContent='center'>
						<QRCodeSVG value={`https://www.mogua.io/deep-linking?channel=${channel}&referrer=${puppy}&otd=true`} size={96} />
					</Box>}
					<Typography fontSize={72}>🐶</Typography >
					{isMobile && <Button color='success' onClick={moguaCall} sx={{ width: '100%' }}>Download</Button>}
				</Card>
				<Card size='lg' sx={{ alignItems: 'center' }}>
					{!isMobile && <Box width={96} display='flex' justifyContent='center'>
						<QRCodeSVG value={`https://www.mogua.io/deep-linking?channel=${channel}&referrer=${fox}&otd=true`} size={96} />
					</Box>}
					<Typography fontSize={72}>🦊</Typography>
					{isMobile && <Button color='success' onClick={moguaCall} sx={{ width: '100%' }}>Download</Button>}
				</Card>
			</Box>
			<Typography>2. Install the app from Google Play Store or Apple App Store.</Typography>
			<Typography>3. Launch the app and you should find the following information indicating which animal you chose.</Typography>
			<Box textAlign='center'>
				<Card sx={{ p: 0, overflow: 'hidden' }}>
					<img alt='' src='/images/deferred-screenshot.png' height={480} />
				</Card>
				<Typography fontStyle='italic' mt={2}>(Example)</Typography>
			</Box>
			<Typography>4. If the app is already installed, you will be directed to the following page within the demo app.</Typography>
			<Box textAlign='center'>
				<Card sx={{ p: 0, overflow: 'hidden' }}>
					<img alt='' src='/images/direct-screenshot.png' height={480} />
				</Card>
				<Typography fontStyle='italic' mt={2}>(Example)</Typography>
			</Box>
			<Typography>5. Sign in and go to the Mogua Dashboard to access the following statistical data.</Typography>
			<Card size='sm' sx={{ width: '60%' }}>
				<CardOverflow sx={{ fontWeight: 'bold', background: Colors.grayBG, py: 1, borderBottom: '1px var(--joy-palette-neutral-300, #CDD7E1) solid' }}>
					<Grid container columns={4.25} textAlign='center' >
						<Grid xs={1} sx={gridSx}>Param Name</Grid><Grid xs={1} sx={gridSx}>Param Value</Grid><Grid xs={1} sx={gridSx}>Count</Grid><Grid xs={1} sx={gridSx}>Event</Grid><Grid xs={0.25}>···</Grid>
					</Grid>
				</CardOverflow>
				<CardContent sx={{ background: '#FFF', py: 1 }}>
					<Grid container columns={4.25} textAlign='center'>
						<Grid xs={1} sx={gridSx}>channel</Grid><Grid xs={1} sx={gridSx}>Mogua Deep Link</Grid><Grid xs={1} sx={gridSx}>1</Grid><Grid xs={1} sx={gridSx}>App Installation</Grid><Grid xs={0.25}>···</Grid>
					</Grid>
					<Divider inset='context' sx={{ my: 1.5 }} />
					<Grid container columns={4.25} textAlign='center'>
						<Grid xs={1} sx={gridSx}>referrer</Grid><Grid xs={1} sx={gridSx}>🐶</Grid><Grid xs={1} sx={gridSx}>1</Grid><Grid xs={1} sx={gridSx}>App Installation</Grid><Grid xs={0.25}>···</Grid>
					</Grid>
				</CardContent>
			</Card>
			<Button size='lg' endDecorator={<KeyboardArrowRight />} sx={{ mt: 2, pr: 2, "--Button-gap": "4px" }} onClick={()=>nav('/sign-up')}>Sign Up Now!</Button>
			<Divider sx={{ mt: 5 }} />
		</Box>
	</>);
}

const Section3: React.FC = () => {
	const nav = useNavigate();
	return (<>
		<SectionIntro textAlign='center' mt={9} mx='auto' sx={{ ...Dimens.autoContentWidth }}
			title='Quick to integrate. Design for developers'
			desc='With a few lines of code you can have Mogua integrated in your app written in any popular language, and any framework. We provide SDKs and integraion guidances to help you succeed on your implementation.'
			/>
		<Box textAlign='center' mb={9} mx='auto' sx={{ ...Dimens.autoContentWidth }}>
			<Box display='flex' flexDirection='row' justifyContent='center' gap={2} mt={4}>
				<Chip size='lg' variant="plain" startDecorator={<AndroidSVG />}>Android</Chip>
				<Chip size='lg' variant="plain" startDecorator={<AppleSVG />}>iOS</Chip>
				<Chip size='lg' variant="plain" startDecorator={<FlutterSVG />}>Flutter</Chip>
				<Chip size='lg' variant="plain" startDecorator={<UnitySVG />}>Unity</Chip>
				<Chip size='lg' variant="plain" startDecorator={<CocosSVG />}>Cocos2d</Chip>
			</Box>
			<Button size='lg' variant='outlined' color='neutral' startDecorator={<FontAwesomeIcon icon={faBook} />} sx={{ mt: '30px' }} onClick={()=>nav('/docs')}>Documentation</Button>
		</Box>
	</>);
}


