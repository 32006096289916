import * as React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import User from '../models/User';

import { contactUrl } from '../GlobalConfig';

import { HeroCard } from './HeroCard';
import { Typography, Divider, Box, Button, Link, Snackbar } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'


export const SignMid: React.FC<{ email: string }> = ({ email }) => {
	const { t } = useTranslation('account');
	const [snack, setSnack] = React.useState<boolean>(false);
	const [loading, setLoading] = React.useState<boolean>(false);
	
	return <>
		<HeroCard title={t('verifyTitle')}>
			<Typography><Trans t={t} i18nKey='verifyTips' values={{ email: email }} components={[<Typography variant='soft' color='warning' />]} /></Typography>
			<br />
			<Divider inset='none' />
			<Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between'>
				<Button loading={loading} variant='outlined' color='neutral' onClick={()=>{
					setLoading(true);
					User.resendEmail(email, {
						onSuccess: () => {
							setLoading(false);
							setSnack(true);
						},
						onError: (error) => {
							setLoading(false);
							alert(error);
						},
					});
				}}>{t('verifyResendButton')}</Button>
				<Typography fontSize='sm'><Trans t={t} i18nKey='verifyTipsHelp' components={[<Link href={contactUrl} />]} /></Typography>
			</Box>
    	</HeroCard>
		<Snackbar
			variant='solid'
			color='success'
			startDecorator={<FontAwesomeIcon icon={faCircleCheck} />}
			anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
			autoHideDuration={2000}
			open={snack} onClose={()=>setSnack(false)}
			>{t('verifySent')}</Snackbar>
    </>;
}


