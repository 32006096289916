import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Platform, EventType, DataFilters } from '../models/Data';

import { Colors } from '../Resources';

import { Card, Input, Select, Option, Divider, Button } from '@mui/joy';
import { Dropdown, MenuButton, Menu, MenuItem } from '@mui/joy';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faArrowRight, faMagnifyingGlass, faCalendarCheck } from '@fortawesome/free-solid-svg-icons';

import dayjs, { Dayjs } from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
dayjs.extend(minMax);


export const platformReadable = new Map<Platform, string>([
	[Platform.all, 'platform.all'],
	[Platform.android, 'platform.android'],
	[Platform.ios, 'platform.ios'],
]);

export const eventTypeReadable = new Map<EventType, string>([
	[EventType.all, 'eventType.all'],
	[EventType.appDownload, 'eventType.appDownload'],
	[EventType.appInstalled, 'eventType.appInstalled'],
	[EventType.appWakeup, 'eventType.appWakeup'],
]);

const dataFiltersToSearchParams = (filters: DataFilters) => {
	const sp: URLSearchParams = new URLSearchParams();
	sp.set('pk', filters.paramKey);
	sp.set('pv', filters.paramValue);
	sp.set('pf', filters.platform);
	sp.set('et', filters.eventType);
	sp.set('start', filters.dateStart.format('YYYY-MM-DD'));
	sp.set('end', filters.dateEnd.format('YYYY-MM-DD'));
	return sp;
}

const searchParamsToDataFilters = (sp: URLSearchParams) => {
	let d1 = dayjs(sp.get('start'));
	d1 = d1.isValid() ? d1 : dayjs();
	let d2 = dayjs(sp.get('end'));
	d2 = d2.isValid() ? d2 : dayjs();
	return {
		paramKey: sp.get('pk'),
		paramValue: sp.get('pv'),
		platform: sp.get('pf') ?? Platform.all,
		eventType: sp.get('et') ?? EventType.all,
		dateStart: dayjs.min(d1, d2)?.startOf('d'),
		dateEnd: dayjs.max(d1, d2)?.endOf('d'),
	} as DataFilters;
}

interface FilterToolbarProps {
	loading?: boolean;
	allowEventTypeFilter?: boolean;
	initFilters?: DataFilters;
	onSubmit?: (filters: DataFilters) => void;
}

const dateInputSx = {
	min: '1970-01-01',
	max: '2042-12-31',
	width: 150,
}

const FilterToolbar: React.FC<FilterToolbarProps> = ({ loading = false, allowEventTypeFilter = true, initFilters, onSubmit }) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const filters = searchParamsToDataFilters(searchParams);
	const [start, setStart] = React.useState<Dayjs>(filters.dateStart);
	const [end, setEnd] = React.useState<Dayjs>(filters.dateEnd);
	const { t } = useTranslation('dataFilter');

	React.useEffect(() => {
		onSubmit && onSubmit(filters);
	}, []);

	return (<>
		<Card component='form' orientation='horizontal' sx={{
			alignItems: 'center',
			flexWrap: 'wrap',
			'& .fa-arrow-right': { color: Colors.gray2 },
		}} onSubmit={(event) => {
			event.preventDefault();
			const data = new FormData(event.currentTarget);
			const filters = searchParamsToDataFilters(data as URLSearchParams);
			const sp = dataFiltersToSearchParams(filters);

			setStart(filters.dateStart);
			setEnd(filters.dateEnd);
			setSearchParams(sp);

			onSubmit && onSubmit(filters);
		}}>
			<Input size='sm' disabled={loading} type='search' name='pk' placeholder={t('paramName')} defaultValue={filters.paramKey} />
			<Input size='sm' disabled={loading} type='search' name='pv' placeholder={t('paramValue')} defaultValue={filters.paramValue} />
			<Divider inset='none' />
			<Select size='sm' disabled={loading} name='pf' defaultValue={filters.platform} sx={{ minWidth: 150 }}>
				{Array.from(platformReadable.entries()).map(e => <Option key={e[0]} value={e[0]}>{t(e[1])}</Option>)}
			</Select>
			<Select size='sm' disabled={!allowEventTypeFilter || loading} name='et' defaultValue={filters.eventType} sx={{ minWidth: 150 }}>
				{Array.from(eventTypeReadable.entries()).map(e => <Option key={e[0]} value={e[0]}>{t(e[1])}</Option>)}
			</Select>
			<Divider inset='none' />
			<Input size='sm' disabled={loading} type='date' key={'s' + start?.format()} defaultValue={start?.format('YYYY-MM-DD')} name='start' sx={dateInputSx} />
			<FA icon={faArrowRight} />
			<Input size='sm' disabled={loading} type='date' key={'e' + end?.format()} defaultValue={end?.format('YYYY-MM-DD')} name='end' sx={dateInputSx} />
			<DateMenu loading={loading} onSelected={(start, end) => {
				setStart(start);
				setEnd(end);
			}} />
			<Button size='sm' loading={loading} type='submit' startDecorator={<FA icon={faMagnifyingGlass} />}>{t('search')}</Button>
		</Card>
	</>);
}

export default FilterToolbar;


export const DateMenu: React.FC<{ loading?: boolean, onSelected: (start: Dayjs, end: Dayjs) => void }> = ({ loading = false, onSelected }) => {
	const { t } = useTranslation('dataFilter');
	return <Dropdown>
		<MenuButton size='sm' disabled={loading}><FA icon={faCalendarCheck} /></MenuButton>
		<Menu size='sm'>
			<MenuItem onClick={()=>{
				const now = dayjs();
				onSelected(now, now);
			}}>{t('today')}</MenuItem>
			<MenuItem onClick={()=>{
				const yesterday = dayjs().subtract(1, 'd');
				onSelected(yesterday, yesterday);
			}}>{t('yesterday')}</MenuItem>
			<Divider />
			<MenuItem onClick={()=>{
				const now = dayjs();
				const start = now.startOf('w');
				onSelected(start, now);
			}}>{t('thisWeek')}</MenuItem>
			<MenuItem onClick={()=>{
				const now = dayjs();
				const start = now.startOf('M');
				onSelected(start, now);
			}}>{t('thisMonth')}</MenuItem>
			<Divider />
			<MenuItem onClick={()=>{
				const end = dayjs().startOf('w').subtract(1, 'd');
				const start = end.startOf('w');
				onSelected(start, end);
			}}>{t('lastWeek')}</MenuItem>
			<MenuItem onClick={()=>{
				const end = dayjs().startOf('M').subtract(1, 'd');
				const start = end.startOf('M');
				onSelected(start, end);
			}}>{t('lastMonth')}</MenuItem>
			<MenuItem onClick={()=>{
				const yesterday = dayjs().subtract(1, 'd');
				const before = yesterday.subtract(6, 'd');
				onSelected(before, yesterday);
			}}>{t('last7Days')}</MenuItem>
			<MenuItem onClick={()=>{
				const yesterday = dayjs().subtract(1, 'd');
				const before = yesterday.subtract(29, 'd');
				onSelected(before, yesterday);
			}}>{t('last30Days')}</MenuItem>
		</Menu>
	</Dropdown>;
}


