import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { AppWorkspaceContext } from './AppWorkspace';
import { useSettings } from '../models/Settings';
import Data from '../models/Data';
import dayjs from 'dayjs';

import FilterToolbar, { eventTypeReadable } from './FilterToolbar';
import WorkspaceData from './WorkspaceData';
import { NotifyExpired, NotifyTestMode } from './Notifications';
import { GridColDef } from '@mui/x-data-grid';


const AppParameters: React.FC = () => {
	const app = React.useContext(AppWorkspaceContext)!;
	const [data, setData] = React.useState<Array<Object>>();
	const [loading, setLoading] = React.useState<boolean>(false);
	const { t } = useTranslation('dataFilter');
	const settings = useSettings();

	const config = React.useMemo(()=>{
		return Array.from<GridColDef>([
			{ field: 'TagName', headerName: t('paramName') },
			{ field: 'TagValue', headerName: t('paramValue') },
			{ field: 'Count', headerName: t('count'), valueGetter: (value: number) => value.toLocaleString() },
			{ field: 'EventName', headerName: t('event'), valueGetter: (value) => t(eventTypeReadable.get(value) ?? 'undefined') },
			{ field: 'FirstAppear', headerName: t('firstReport'), valueGetter: (value) => dayjs(value * 1000).format(settings.dateFormat + ' HH:mm:ss') },
			{ field: 'LastAppear', headerName: t('lastReport'), valueGetter: (value) => dayjs(value * 1000).format(settings.dateFormat + ' HH:mm:ss') },
		]).map((e) => {
			e.disableColumnMenu = true;
			e.resizable = false;
			e.sortable = false;
			e.flex = 1;
			e.align = 'center';
			e.headerAlign = 'center';
			return e;
		});
	}, [t, settings.dateFormat]);

	return (<>
		{app.isExpired() && <NotifyExpired />}
		{app.isTestEnabled && <NotifyTestMode />}
		<FilterToolbar loading={loading} onSubmit={(filters)=>{
			setData(undefined);
			setLoading(true);
			filters.appId = app.id;
			const data = new Data(filters);
			data.getParameters({
				onSuccess: (data) => {
					setLoading(false);
					setData(data);
				},
				onError: (error) => {
					setLoading(false);
					alert(error);
				},
			});
		}} />
		<WorkspaceData data={data} config={config} loading={loading} />
	</>);
}

export default AppParameters;


