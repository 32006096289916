import * as React from 'react';
import { useTranslation, Trans } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useLangNavigate as useNavigate } from '../modules/i18n';

import { Colors } from '../Resources';
import { serviceConfig, contactUrl } from '../GlobalConfig';
import { http, ContentType } from '../Utils';

import SEO from '../modules/SEO';

import { HeroCard } from './HeroCard';
import { Typography, Chip, CircularProgress, Divider } from '@mui/joy';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'
import { faCircleRight } from '@fortawesome/free-solid-svg-icons'


const Checkout: React.FC = () => {
	const [status, setStatus] = React.useState<string>();
	const [countdown, setCountdown] = React.useState<number>(5);
	const [searchParams, setSearchParams] = useSearchParams();
	const sessionId = searchParams.get('sessionId');
	const appId = searchParams.get('appId');
	const nav = useNavigate();
	const { t } = useTranslation(['dashboard', 'common']);

	React.useEffect(() => {
		if (!sessionId) return;
		const host = serviceConfig.backendhost + `/api/stripe?sessionId=${sessionId}&appId=${appId}`
		http(host, {
			body: {},
			method: "GET",
			contentType: ContentType.json,
		}).then((res) => setStatus(res.success ? res.status : res.msg)).catch(e => alert(e));
	}, [sessionId, appId]);

	React.useEffect(() => {
		if (countdown === 0) {
			nav(`/app/${appId}`);
			return;
		}
		setTimeout(()=>setCountdown(countdown - 1), 1000);
	}, [countdown, nav, appId]);

	const suffix = <Typography
		endDecorator={<Chip component='span' color='primary' onClick={()=>{window.location.href=contactUrl}} endDecorator={<FA icon={faCircleRight} />}>{t('contactUs', { ns: 'common' })}</Chip>}
		sx={{ color: Colors.gray3, fontSize: 14 }}>{t('anyQuestions', { ns: 'common' })}</Typography>

	return <>
		<SEO
			title='Mogua | Checkout'
			description='Waiting for checkout.'
			url='https://www.mogua.io/checkout' />
		{status === undefined ? 
		<HeroCard title={t('checkoutProcessing')} suffix={suffix}>
			<Divider inset='none' />
			<CircularProgress sx={{ my: 2, alignSelf: 'center' }} />
		</HeroCard> : 
		status === 'complete' ? 
		<HeroCard title={t('checkoutSuccess')} suffix={suffix}>
			<Divider inset='none'>❤️</Divider>
			<Typography><Trans t={t} i18nKey='checkoutSuccessRedirect' values={{ countdown: countdown }} /></Typography>
		</HeroCard> :
		<HeroCard title={t('checkoutFailed')} suffix={suffix}>
			<Divider inset='none' />
			<Typography><Trans t={t} i18nKey='checkoutFailedRedirect' values={{ countdown: countdown }} /></Typography>
		</HeroCard>}
	</>;
}

export default Checkout;


