import * as React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Mogua } from 'moguasdk';
import { Utils, moguaDemoAppKey } from '../GlobalConfig';

import AppIcon, { stringToHsl } from './AppIcon';
import { Box, Typography, Button, Card, Snackbar } from '@mui/joy';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'


const AppMockupSite: React.FC = () => {
	const { t } = useTranslation(['mockupSite', 'common']);
	const [ready, setReady] = React.useState<boolean>(false);
	const [snack, setSnack] = React.useState<{ type: 'danger' | 'warning', child: React.ReactNode } | undefined>(undefined);
	const [searchParams, setSearchParams] = useSearchParams();
	const appKey = searchParams.get('appKey') ?? undefined
	const appName = searchParams.get('appName') ?? 'APP';
	const appIcon = searchParams.get('appIcon') ?? undefined;
	const isMoguaDemo = appKey === moguaDemoAppKey;
	const key = searchParams.get('key') ?? undefined;
	const value = searchParams.get('value') ?? undefined;

	React.useEffect(() => {
		if (appKey === undefined || Mogua.isReady()) return;
		let params: { [id: string]: string } = { channel: 'Live Demo Site', referrer: 'Mogua Team' }
		if (key !== undefined && value !== undefined) {
			params[key] = value;	
		}
		Mogua.init({
			appKey: appKey,
			params: params,
			onSuccess: () => setReady(true),
		});
	}, [appKey, key, value]);

	const isMobile = Utils.isMobile();
	return <Box display='flex' flexDirection='column' flex={1} sx={{ bgcolor: isMoguaDemo ? '#131b26' : stringToHsl(appName) }}>
		<Box display='flex' alignItems='center' py={1} gap={1} sx={{ width: '100%', bgcolor: '#000000AA' }}>
			<span style={{ width: 1 }} />
			<AppIcon size={isMobile ? 48 : 34} alt={appName} src={appIcon} />
			<Typography fontSize={isMobile ? 24 : 16} fontWeight='bold' textColor='white'>{appName}</Typography>
		</Box>
		<Typography overflow='hidden' textColor='white' flex={1} fontSize={isMobile ? 62 : 44} fontWeight='bold' lineHeight={1} letterSpacing={-1} sx={{ m: 2 }}>
			{t('welcomeToDownload', { app: appName })}
		</Typography>
		<Box display='flex' sx={{ width: '100%' }}>
			<Button disabled={!ready} loading={!ready} size={isMobile ? 'lg' : 'md'} sx={{ mx: 2, flex: 1 }} onClick={()=>{
				Mogua.openOrDownload({
					onOpen: (url: string | undefined)=>{
						if (url !== undefined) return url;
						setSnack({ type: 'warning', child: <Trans t={t} i18nKey={Utils.isAndroid() ? 'noAndroidSchemeTips' : 'noIosSchemeTips'} /> });
						return undefined;
					},
					onDownload: (url: string | undefined)=>{
						if (url !== undefined) return url;
						setSnack({ type: 'danger', child: <Trans t={t} i18nKey={Utils.isAndroid() ? 'noAndroidDownloadUrlTips' : 'noIosDownloadUrlTips'} /> });
						return undefined;
					},
					onError: (error: any)=>{
						setSnack({
							type: 'danger',
							child: <>{error.msg}</>,
						});
					},
				});
			}}>{t('download', { ns: 'common' })}</Button>
		</Box>
		<Card sx={{ m: 2, bgcolor: '#000000AA', border: 'none' }}>
			<Typography textColor='#FFFFFFCC'><Trans t={t} i18nKey='downloadTips' /></Typography>
		</Card>
		<Snackbar
			variant='outlined'
			color={snack?.type}
			sx={{ bgcolor: snack?.type === 'warning' ? '#fbf0e3' : '#fce4e4' }}
			startDecorator={<FA icon={faCircleExclamation} />}
			anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
			autoHideDuration={5000}
			open={snack !== undefined} onClose={()=>setSnack(undefined)}
			><Typography fontSize='sm' textColor='inherit'>{snack?.child}</Typography></Snackbar>
	</Box>
}

export default AppMockupSite;


