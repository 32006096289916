import dayjs from 'dayjs';

export const serviceConfig = {
    backendhost: process.env.REACT_APP_BACKEND_HOST,
    host: process.env.REACT_APP_API_BASE_URL,
    entrypoint: process.env.REACT_APP_ENTRYPOINT,
    version: process.env.REACT_APP_VERSION,
    lng: "en-US",
}

export const Utils = {
    isMobile: () => {
        return navigator.userAgent.toLowerCase().indexOf("mobile") > -1;
    },
    isIOS: () => {
        const a = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform);
        const b = (navigator.userAgent.includes("Mac") && "ontouchend" in document);
        return a || b;
    },
    isAndroid: () => {
        return navigator.userAgent.toLowerCase().indexOf("android") > -1;
    },
    getFormatedDateString: (utcTimestamp: number) => {
        return new Date(utcTimestamp * 1000).toDateString().substring(4)
    },
    getSimpleDateString: (utcTimestamp: number) => {
        return dayjs.unix(utcTimestamp).format("YYYY-MM-DD")
    },
    getTimeDelta: (utcTimestamp: number) => {
        return utcTimestamp - Date.now() / 1000
    },

    getSimpleTimeString: (utcTimestamp: number) => {
        return dayjs.unix(utcTimestamp).format("YYYY-MM-DD HH:mm:ss")
    },

    AppLog: (action: string, data: {[id:string]:string}) => {
        var url = `${serviceConfig.backendhost}/api/l?action=${encodeURIComponent(action)}&data=${encodeURIComponent(JSON.stringify(data))}&page=${encodeURIComponent(window.location.href)}`
        var image = document.createElement('img');
        image.src = url;
        image.setAttribute("style", 'display: none;');
        document.body.appendChild(image);
    },
}

export const contactUrl = 'mailto:hello@mogua.io';

export const moguaDemoAppKey = '43ba757b8bc3';