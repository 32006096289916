import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useLangNavigate as useNavigate } from '../modules/i18n';

import { Mogua } from 'moguasdk';
import { Colors, Dimens } from '../Resources';
import { Utils, moguaDemoAppKey } from '../GlobalConfig';

import SEO from '../modules/SEO';
import NavBar from './NavBar';
import Footer from './Footer';
import { SectionIntro } from './SectionIntro';
import { QRCodeSVG } from 'qrcode.react';

import { Box, Typography, Button, Stack, Divider } from '@mui/joy';
import { List, ListItem, ListItemDecorator } from '@mui/joy';
import { Card, CardOverflow, CardContent } from '@mui/joy';
import { Grid } from '@mui/joy';

import { KeyboardArrowRight, LooksOneRounded, LooksTwoRounded, Looks3Rounded } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMicrochip, faClipboardCheck, faFlaskVial, faRocket } from '@fortawesome/free-solid-svg-icons'


const Products: React.FC = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const openToDownload = searchParams.get('otd');

	const moguaCall = React.useCallback(() => {
		if (Mogua.isReady()) return;
		Mogua.init({
			appKey: moguaDemoAppKey,
			onSuccess: () => Utils.isAndroid() ? Mogua.openOrDownload() : alert('Mogua Demo iOS is not ready yet.'),
			// onError: (error) => alert(error),
		});
	}, []);

	React.useEffect(() => {
		if (!openToDownload) return;
		moguaCall();
	}, [openToDownload, moguaCall]);

	return (<>
		<SEO
			title="Mogua: Web to app attribution solution"
			description="Web to app parameter passing solution. Track Android and iOS apps' installation from web referrals with our attribution SDK."
			url="https://www.mogua.io/products" />
		<NavBar />
		<Divider />
		<Banner />
		<Section1 />
		<Section2 moguaCall={moguaCall} />
		<Section3 />
		<Footer />
	</>);
}

export default Products;

const Banner: React.FC = () => {
	const nav = useNavigate();
	return (<>
		<Box textAlign='center' mt={9} mx='auto' sx={{ ...Dimens.autoContentWidth }}>
			<Typography sx={{ color: Colors.black, fontSize: '72px', fontWeight: 'bold', lineHeight: 1.1, letterSpacing: '-0.15rem', textAlign: 'center' }}>
				An efficient way to identify your app's installation source
			</Typography>
			<Typography mx={{ xs: "50px", sm: "50px", md: "100px", lg: "200px" }} sx={{ color: Colors.gray3, fontSize: '20px', lineHeight: 1.3, mt: "20px" }}>
				With our solution, you can build a seamless deep linking experience for your app's new users when they are brought from the web landing pages.
			</Typography>
			<Button size="lg" endDecorator={<KeyboardArrowRight />} sx={{
				mt: "36px",
				pr: "16px",
				"--Button-gap": "4px",
			}} onClick={()=>nav('/sign-up')}>Get Started</Button>
			<Divider sx={{ mt: "72px" }} />
		</Box>
	</>);
}


const Section1: React.FC = () => {
	const boxSx = { flex: 1, width: { xs: '75%', sm: '75%', md: null }};
	const cardSx = { p: 0, overflow: 'hidden', mb: '40px' };
	return (<>
		<SectionIntro textAlign='center' mt={9} mx='auto' sx={{ ...Dimens.autoContentWidth }}
			title='Identify app’s installation sources through deferred deep links'
			desc='Deferred deep links can route users to specific content through an install.
			The link will first redirect to the App Store or Play Store, and once the app is downloaded and opened, the user will be taken to the content they expect.
			By passing custom parameters from content to the app with the deep link, the app’s installation sources can be identified.'
			/>
		<Box mt={9} mx='auto' sx={{ ...Dimens.autoContentWidth }}>
			<Stack direction={{ xs: 'column', sm: 'column', md: 'row' }} alignItems="center" justifyContent="center" spacing={4}>
				<Box sx={boxSx}>
					<Card sx={cardSx}><img alt="" src="/images/screenshot-0.png" /></Card>
					<Typography textAlign='center'><LooksOneRounded /><br />Scan a QR Code(Or click a link) to open the landing page.</Typography>
				</Box>
				<Box sx={boxSx}>
					<Card sx={cardSx}><img alt="" src="/images/screenshot-1.png" /></Card>
					<Typography textAlign='center'><LooksTwoRounded /><br />After parameters are submitted, redirect to the Google Play Store (Or Apple AppStore).</Typography>
				</Box>
				<Box sx={boxSx}>
					<Card sx={cardSx}><img alt="" src="/images/screenshot-3.png" /></Card>
					<Typography textAlign='center'><Looks3Rounded /><br />Install and launch the app. Get the parameters and do your jobs.</Typography>
				</Box>
			</Stack>
			<Divider sx={{ mt: "72px" }} />
		</Box>
	</>);
}

const Section2: React.FC<{ moguaCall: ()=>void }> = ({ moguaCall }) => {
	const isMobile = Utils.isMobile();
	const gridSx = { textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' };
	const channel = 'mogua_products_page';
	const puppy = '%F0%9F%90%B6';
	const fox = '%F0%9F%A6%8A';
	return (<>
		<SectionIntro textAlign='center' mt={9} mx='auto' sx={{ ...Dimens.autoContentWidth }}
			title={"Try instantly on our live demo"}
			desc={"Try how the parameters on a webpage are passed to an app with the MoguaSDK"}
			/>
		<Box mt={9} mx='auto' sx={{ ...Dimens.autoContentWidth }}>
			<Stack 
				direction={{ xs: 'column', sm: 'column', md: 'row'}}
				alignItems={{ xs: 'center', sm: 'center', md: 'flex-start' }}
				justifyContent='center' mx='auto' sx={{ ...Dimens.autoContentWidth }}
				spacing={8}
				>
				<Box>
					<Card size='lg' orientation={isMobile ? 'vertical' : 'horizontal'} sx={{ alignItems: 'center' }}>
						<Box display='flex' flexDirection='row' alignItems='center' gap={2}>
							<Typography fontSize={72}>🐶</Typography >
							<Typography fontSize='sm'>"Hey, fur friends! 🐾 {isMobile ? 'Download' : 'Scan my QR code to download'} the best app for pups and their humans! 🐶📲 #PuppyApproved"</Typography>
						</Box>
						{!isMobile && <Box width={96} display='flex' justifyContent='center'>
							<QRCodeSVG value={`https://www.mogua.io/products?channel=${channel}&referrer=${puppy}&otd=true`} size={96} />
						</Box>}
						{isMobile && <Button color='success' onClick={moguaCall} sx={{ width: '100%' }}>Download</Button>}
					</Card>
					<br />
					<Card size='lg' orientation={isMobile ? 'vertical' : 'horizontal'} sx={{ alignItems: 'center' }}>
						{!isMobile && <Box width={96} display='flex' justifyContent='center'>
							<QRCodeSVG value={`https://www.mogua.io/products?channel=${channel}&referrer=${fox}&otd=true`} size={96} />
						</Box>}
						<Box display='flex' flexDirection='row' alignItems='center' gap={2}>
							<Typography fontSize='sm'>
								"Hey there, handsome. Looking for a little adventure? {isMobile ? 'Download' : 'Scan my QR code'} and let's explore this app together. Trust me, you won't be disappointed. 😉💋"
							</Typography>
							<Typography fontSize={72}>🦊</Typography>
						</Box>
						{isMobile && <Button color='success' onClick={moguaCall} sx={{ width: '100%' }}>Download</Button>}
					</Card>
				</Box>
				<List>
					<ListItem sx={{ alignItems: 'start' }}>
						<ListItemDecorator><LooksOneRounded /></ListItemDecorator>
						{isMobile ? 'Choose' : 'Scan the code from'} the 🐶 or 🦊 influencer to download our demo app on your phone.
					</ListItem>
					<br />
					<ListItem sx={{ alignItems: 'start' }}><ListItemDecorator><LooksTwoRounded /></ListItemDecorator>After downloading and launching the demo app, you should find the following information inside, indicating which influencer you chose.</ListItem>
					<br />
					<ListItem>
						<ListItemDecorator />
						<Card sx={{ backgroundColor: Colors.grayBG }}>
							💌 You are invited by 🐶.
						</Card>
						<Typography fontStyle='italic'>(Example)</Typography>
					</ListItem>
					<br />
					<ListItem sx={{ alignItems: 'start' }}><ListItemDecorator><Looks3Rounded /></ListItemDecorator>Sign in and go to the Mogua Dashboard to access the following statistical data.</ListItem>
					<br />
					<ListItem>
						<Card size='sm' sx={{ width: '100%' }}>
							<CardOverflow sx={{ fontWeight: 'bold', background: Colors.grayBG, py: 1, borderBottom: '1px var(--joy-palette-neutral-300, #CDD7E1) solid' }}>
								<Grid container columns={4.25} textAlign='center' >
									<Grid xs={1} sx={gridSx}>Param Name</Grid><Grid xs={1} sx={gridSx}>Param Value</Grid><Grid xs={1} sx={gridSx}>Count</Grid><Grid xs={1} sx={gridSx}>Event</Grid><Grid xs={0.25}>···</Grid>
								</Grid>
							</CardOverflow>
							<CardContent sx={{ background: '#FFF', py: 1 }}>
								<Grid container columns={4.25} textAlign='center'>
									<Grid xs={1} sx={gridSx}>channel</Grid><Grid xs={1} sx={gridSx}>products_page</Grid><Grid xs={1} sx={gridSx}>1</Grid><Grid xs={1} sx={gridSx}>App Installation</Grid><Grid xs={0.25}>···</Grid>
								</Grid>
								<Divider inset='context' sx={{ my: 1.5 }} />
								<Grid container columns={4.25} textAlign='center'>
									<Grid xs={1} sx={gridSx}>referrer</Grid><Grid xs={1} sx={gridSx}>🐶</Grid><Grid xs={1} sx={gridSx}>1</Grid><Grid xs={1} sx={gridSx}>App Installation</Grid><Grid xs={0.25}>···</Grid>
								</Grid>
							</CardContent>
						</Card>
					</ListItem>
				</List>
			</Stack>
			<Divider sx={{ mt: "72px" }} />
		</Box>
	</>);
}

const Section3: React.FC = () => {
	const nav = useNavigate();
	return (<>
		<SectionIntro textAlign='center' mt={9} mx='auto' sx={{ ...Dimens.autoContentWidth }} title="Why choose Mogua? 🤔" desc="" />
		<Box textAlign='center' mt={5} mb={9} mx='auto' sx={{ ...Dimens.autoContentWidth }}>
			<Grid container rowSpacing={6} columnSpacing={6} textAlign='left'>
				<Grid xs={12} sm={6}>
					<SectionIntro
						icon={<FontAwesomeIcon icon={faMicrochip} />}
						title={"Update for New Devices"}
						desc={"Our SDK will continuously adapt to new mobile phone models and collectable user parameters, optimize matching algorithms, and improve the accuracy of user recognition."}
					/>
				</Grid>
				<Grid xs={12} sm={6}>
					<SectionIntro
						icon={<FontAwesomeIcon icon={faClipboardCheck} />}
						title={"Comply with App Stores"}
						desc={"All the information we collect complies with the privacy policy requirements of the Apple Store and Google Play. We also provide guidance in collecting user information and making statement in privacy policies to avoid the risk of app restrictions in the app store."}
					/>
				</Grid>
				<Grid xs={12} sm={6}>
					<SectionIntro
						icon={<FontAwesomeIcon icon={faFlaskVial} />}
						title={"Continuous Algo Optimization"}
						desc={"Our algorithm will continue to optimize the model based on existing data accumulation, improve the accuracy of matching, and reduce the possibility of customers brought by promotions being attributed to organize traffic."}
					/>
				</Grid>
				<Grid xs={12} sm={6}>
					<SectionIntro
						icon={<FontAwesomeIcon icon={faRocket} />}
						title={"Quick to Integrate"}
						desc={"We focus on providing great user experience for developers, without the need for complex configuration settings, you can quickly integrate the SDK into your application."}
					/>
				</Grid>
			</Grid>
			<Button size='lg' variant='outlined' color='neutral' endDecorator='😎' sx={{ mt: 5 }} onClick={()=>nav('/sign-up')}>Try It Now!</Button>
		</Box>
	</>);
}
