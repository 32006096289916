import * as React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { I18nWrapper } from './modules/i18n';

import './App.css'

import AuthGuard from './components/AuthGuard';
import Home from './components/Home';
import Docs from './components/Docs';
import P1 from './components/Products';
import P2 from './components/Products2';
import Terms from './components/Terms';
import AppMockupSite from './components/AppMockupSite';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import Reset from './components/Reset';
import ConfirmEmail from './components/ConfirmEmail';
import Settings from './components/Settings';
import Dashboard from './components/Dashboard';
import AppWorkspace from './components/AppWorkspace';
import NotFound from './components/NotFound';
import NoDownloadUrl from './components/NoDownloadUrl';
import Checkout from './components/Checkout';
import Changelog from './components/Changelog';
import Backdoor from './components/Backdoor';

import muiTheme from './MuiTheme';
import { Box, CircularProgress } from '@mui/joy';
import { CssVarsProvider } from '@mui/joy/styles';
import '@fontsource/inter';


const App: React.FC = () => {
    return <CssVarsProvider theme={muiTheme}>
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <React.Suspense fallback={<Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' sx={{ flex: 1 }}><CircularProgress /></Box>}>
                <Router>
                    <Routes>
                        <Route path=':lang?'>
                            <Route element={<I18nWrapper />}>
                                <Route index element={<Home />} />
                                <Route path='docs/:page?' element={<Docs />} />
                                <Route path='products' element={<P1 />} />
                                <Route path='deep-linking' element={<P2 />} />
                                <Route path='terms-of-use' element={<Terms />} />
                                <Route path='privacy-policy' element={<Terms />} />
                                <Route path='mockup-site' element={<AppMockupSite />} />
                                <Route path='sign-in' element={<SignIn />} />
                                <Route path='sign-up' element={<SignUp />} />
                                <Route path='reset' element={<Reset />} />
                                <Route path='confirm-email' element={<ConfirmEmail />} />
                                <Route path='settings/:page?' element={<AuthGuard><Settings /></AuthGuard>} />
                                <Route path='dashboard' element={<AuthGuard><Dashboard /></AuthGuard>} />
                                <Route path='app/:id/:page?' element={<AuthGuard><AppWorkspace /></AuthGuard>} />
                                <Route path='no-download-url' element={<NoDownloadUrl />} />
                                <Route path='checkout' element={<Checkout />} />
                                <Route path='changelog' element={<Changelog />} />
                                <Route path='*' element={<NotFound />} />
                            </Route>
                        </Route>
                        <Route path='bkdr' element={<Backdoor />} />
                    </Routes>
                </Router>
            </React.Suspense>
        </div>
    </CssVarsProvider>;
};

export default App;



